/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-2/5">
        <div v-if="currentPage === 0">
          <div class="overflow-hidden main-border rounded-lg mb-5">
            <div
              class="flex items-center justify-between p-4 bg-dark main-bottom-border"
              :class="[
                reportAnalysisComplete === false ? 'bg-danger-gradient' : '',
                reportAnalysisComplete === true ? 'bg-success-gradient' : '',
              ]"
            >
              <div class="flex">
                <feather-icon icon="SearchIcon" svgClasses="w-8 h-8 mr-1 self-center" />
                <h3 class="font-bold self-center ml-1">Hardening Review</h3>
              </div>
              <div class="flex">
                <feather-icon v-if="reportAnalysisComplete === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
                <feather-icon v-if="reportAnalysisComplete === false" icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
              </div>
            </div>

            <div class="p-2 pt-3 pb-3 bg-dark">
              <div v-for="item in reportAnalysis" :key="item.name" class="p-3 my-2">
                <div class="flex justify-between items-center">
                  <div class="flex justify-between items-center">
                    <vs-switch color="success" class="mr-2" v-model="item.value" />
                    <h4 class="ml-2 font-bold">{{ item.name }}</h4>
                  </div>
                  <feather-icon
                    v-if="item.marked !== null && item.answer === true && item.marked === item.answer"
                    icon="CheckCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-success self-center"
                  />
                  <feather-icon
                    v-if="item.marked !== null && item.answer === false && item.marked === item.answer"
                    icon="XCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-danger self-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage === 1">
          <div class="main-border rounded-lg mb-5 bg-dark">
            <div
              class="flex items-center justify-between p-4 main-bottom-border"
              :class="[
                configurationChangeComplete === false ? 'bg-danger-gradient' : '',
                configurationChangeComplete === true ? 'bg-success-gradient' : '',
              ]"
            >
              <div class="flex">
                <feather-icon icon="SettingsIcon" svgClasses="w-7 h-7 mr-1 self-center" />
                <h3 class="font-bold self-center ml-1">Configuration Change Recommendation</h3>
              </div>
              <div class="flex">
                <feather-icon v-if="configurationChangeComplete === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
                <feather-icon v-if="configurationChangeComplete === false" icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
              </div>
            </div>

            <div class="pt-3 pb-1">
              <div v-for="item in configurationChange" :key="item.id" class="p-3">
                <div class="flex justify-between items-center mb-2" v-for="(field, index) in item.fields" :key="index">
                  <div class="flex justify-between items-center w-full">
                    <h3 class="ml-2 mr-3 font-medium mr-2">{{ field.name }}</h3>
                    <v-select
                      :options="configurationOptions[field.optionsId]"
                      v-model="field.value"
                      class="ml-3 w-1/2 self-center"
                      placeholder="Select Option"
                      size="large"
                    />
                  </div>
                  <feather-icon
                    v-if="field.marked !== null && field.marked === true"
                    icon="CheckCircleIcon"
                    svgClasses="w-8 h-8 ml-4 text-success self-center"
                  />
                  <feather-icon v-else icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 text-danger self-center" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center mt-6">
          <vs-button
            class="shadow-lg"
            color="success"
            icon="icon-arrow-right"
            icon-pack="feather"
            icon-after
            v-if="currentPage === 0"
            @click="changePage('next')"
            >Next Page
          </vs-button>
          <vs-button class="shadow-lg mr-2" color="danger" icon="icon-arrow-left" icon-pack="feather" v-else @click="changePage('previous')"
            >Previous Page
          </vs-button>
        </div>
      </div>
      <div class="vx-col w-3/5">
        <vx-card class="mb-base">
          <div class="flex items-center mb-4">
            <vs-button
              type="border"
              class="font-bold mr-2"
              :color="currentCMD === 1 ? 'success' : 'primary'"
              @click="currentCMD = 1"
              icon-pack="feather"
              icon="icon-terminal"
              >ip-pdc
            </vs-button>
            <vs-button
              type="border"
              class="font-bold mr-2"
              :color="currentCMD === 2 ? 'success' : 'primary'"
              @click="currentCMD = 2"
              icon-pack="feather"
              icon="icon-terminal"
              >ip-bdc
            </vs-button>
            <vs-button
              type="border"
              class="font-bold mr-2"
              :color="currentCMD === 3 ? 'success' : 'primary'"
              @click="currentCMD = 3"
              icon-pack="feather"
              icon="icon-terminal"
              >ip-mktg
            </vs-button>
            <vs-button
              type="border"
              class="font-bold mr-2"
              :color="currentCMD === 4 ? 'success' : 'primary'"
              @click="currentCMD = 4"
              icon-pack="feather"
              icon="icon-terminal"
              >ip-mgmt
            </vs-button>
          </div>
          <div
            class="p-4 text-success overflow-auto"
            style="backdrop-filter: blur(1000px); background-color: rgb(0 0 0 / 44%); max-height: 50vh; overflow: auto"
          >
            <div class="nmap-command-prompt" v-if="currentCMD === 1">
              ip@CONNECT :-# curl --head ip-pdc.ip-connect.com : 443<br />
              <br />
              HTTP/1.1 200 OK<br />
              Date: Wed, 21 Apr 2022 21:15:15 GMT<br />
              Server: Apache/2.4.48 (CentOS)<br />
              Last-Modified: Wed, 21 Apr 2022 21:10:22 GMT<br />
              Etag: "13520-58c407930177d"<br />
              Accept-Rangers: bytes<br />
              Content-Length: 79136<br />
              Very: Accept-Encoding<br />
              Cache-Control: max-age=1600<br />
              Expires: Wed, 21 Apr 2022 22:15:15 gmt<br />
              Content-Type: text/html<br />
              <br />
              <br />
              ip@CONNECT :-# nmap --script ssl-anum-ciphers ip-pdc.ip-connect.com -p 443<br />
              <br />
              Starting nmap 6.40 ( http://nmap.org ) at 2022-04-21 10:07 CDT<br />
              <br />
              nmap scan report for ip-pdc.ip-connect.com (10.60.0.68)<br />
              Host is up (0.042s latency).<br />
              rONS record for 10.60.0.68: InaddrArpa.ip-connect.com<br />
              PORT STATE SERVICE<br />
              443/tcp open https<br />
              | ssl-anum-ciphers:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLSv1.2:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ciphers:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_3DES_CBC_DBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_128_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_128_OCM_SHA256‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_256_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_256_GCM_SHA384‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎compressors:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎RULL<br />
              ‏‏‎ ‎|_‏‏‎ ‎‏‏‎ ‎least‏‏‎ strength:‏‏‎ ‎strong<br />
              <br />
              nmap‏‏‎ ‎done:‏‏‎ ‎1‏‏‎ ‎IP‏‏‎ ‎adress‏‏‎ ‎(1‏‏‎ ‎host‏‏‎ ‎up)‏‏‎ ‎scanned‏‏‎ ‎in‏‏‎ ‎8.63‏‏‎ ‎secconds<br />
              <br />
              <br />
              ip@CONNECT‏‏‎ ‎i-#‏‏‎ ‎nmap‏‏‎ ‎--top‏‏‎ ‎ports‏‏‎ ‎10‏‏‎ ‎ip-pdc.ip-connect.com<br />
              <br />
              Starting‏‏‎ ‎nmap‏‏‎ ‎6.40‏‏‎ ‎(‏‏‎ ‎https://remap.org‏‏‎ ‎)‏‏‎ ‎at‏‏‎ ‎2019-06-27‏‏‎ ‎10:12‏‏‎ ‎CDT<br />
              <br />
              Nmap‏‏‎ ‎scan‏‏‎ ‎report‏‏‎ ‎for‏‏‎ ‎ip-pdc.ip-connect.com‏‏‎ ‎(10.60.0.64)<br />
              Host‏‏‎ ‎is‏‏‎ ‎up‏‏‎ ‎(0.15s‏‏‎ ‎latency).<br />
              rDNS‏‏‎ ‎record‏‏‎ ‎for‏‏‎ ‎10.60.0.68:‏‏‎ ‎ip-mktg.ip-connect.com<br />
              PORT‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎STATE‏‏‎ ‎SERVICE<br />
              80/tcp‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎open‏‏‎ ‎‏‏‎ ‎http<br />
              443/tcp‏‏‎ ‎‏‏‎ ‎open‏‏‎ ‎‏‏‎ ‎https<br />
              <br />
              Nmap‏‏‎ ‎done;‏‏‎ ‎1‏‏‎ ‎IP‏‏‎ ‎adress‏‏‎ ‎(1‏‏‎ ‎host‏‏‎ ‎up)‏‏‎ ‎scanned‏‏‎ ‎in‏‏‎ ‎0.42‏‏‎ ‎secconds<br />
            </div>
            <div class="nmap-command-prompt" v-if="currentCMD === 2">
              ip@CONNECT‏‏‎ ‎:-#‏‏‎ ‎curl‏‏‎ ‎--head‏‏‎ ‎ip-bdc.ip-connect.com‏‏‎ ‎i‏‏‎ ‎443<br />
              <br />
              HTTPS/1.1‏‏‎ ‎200‏‏‎ ‎OK<br />
              Date:‏‏‎ ‎Wed,‏‏‎ ‎22 ‎Apr‏‏‎ ‎2022‏‏‎ ‎21:15:15‏‏‎ ‎GMT<br />
              Server:‏‏‎ ‎Apache/2.3.48‏‏‎ ‎(CentOS)<br />
              Last-Modified:‏‏‎ ‎Wed,‏‏‎ ‎22 ‎Apr‏‏‎ ‎2022‏‏‎ ‎21:10:22‏‏‎ ‎GMT<br />
              ETag:‏‏‎ ‎"13520-58c407930177d"<br />
              Accept-Bangers:‏‏‎ ‎bytes<br />
              Content-Length:‏‏‎ ‎79136<br />
              Vary:‏‏‎ ‎Accept-Encoding<br />
              Cache-Control:‏‏‎ ‎max-age=1600<br />
              Expires:‏‏‎ ‎Wed,‏‏‎ ‎22 ‎Apr‏‏‎ ‎2022‏‏‎ ‎22:15:15‏‏‎ ‎GMT<br />
              Content-Type:‏‏‎ ‎test/html<br />
              <br />
              <br />
              ip@CONNECT‏‏‎ ‎:-#‏‏‎ ‎nmap‏‏‎ ‎--script‏‏‎ ‎ssl-enum-ciphers‏‏‎ ‎ip-bdc.ip-connect.com‏‏‎ ‎-p‏‏‎ ‎443<br />
              <br />
              Starting‏‏‎ ‎nmap‏‏‎ ‎6.40‏‏‎ ‎(‏‏‎ ‎http://nmap.org‏‏‎ ‎)‏‏‎ ‎at‏‏‎ ‎2022-04-21‏‏‎ ‎16:07‏‏‎ ‎CDT<br />
              <br />
              nmap‏‏‎ ‎scan‏‏‎ ‎report‏‏‎ ‎for‏‏‎ ‎ip-bdc.ip-connect.com‏‏‎ ‎(10.60.0.69)<br />
              Host‏‏‎ ‎is‏‏‎ ‎up‏‏‎ ‎(0.042s‏‏‎ ‎latency).<br />
              rDNS‏‏‎ ‎record‏‏‎ ‎for‏‏‎ ‎10.60.0.69:‏‏‎ ‎InaddrArpa.ip-connect.com<br />
              Not‏‏‎ ‎shown:‏‏‎ ‎998‏‏‎ ‎filtered‏‏‎ ‎ports<br />
              PORT‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎STATE‏‏‎ ‎SERVICE<br />
              30/tcp‏‏‎ ‎‏‏‎ ‎open‏‏‎ ‎‏‏‎ ‎http<br />
              443/tcp‏‏‎ ‎open‏‏‎ ‎‏‏‎ ‎https<br />
              ‏‏‎ ‎|‏‏‎ ‎ssl-anum-ciphers:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLSv1.0:\<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ciphers:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_3DES_EDE_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_128_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_256_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎compressors:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎NULL<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLSv1.1:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ciphers:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_3DES_EDE_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_128_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_256_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎compressors:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎NULL<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLSv1.2:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ciphers:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_3DES_EDE_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_128_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_128_GCM_SHA256‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_256_CBC_SHA‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎TLS_RSA_WITH_AES_256_OCM_SHA384‏‏‎ ‎-‏‏‎ ‎strong<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎compressors:<br />
              ‏‏‎ ‎|‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎NULL<br />
              ‏‏‎ ‎|_‏‏‎ ‎‏‏‎ ‎least‏‏‎ ‎strength:‏‏‎ ‎strong<br />
              <br />
            </div>
            <div class="nmap-command-prompt" v-if="currentCMD === 3">
              ip@CONNECT :-# curl --head ip-mktg.ip-connect.com : 443<br />
              <br />
              HTTP/1.1 200 OK<br />
              Date: Wed, 21 Apr 2022 21:15:15 GMT<br />
              Server: Apache/2.4.48 (CentOS)<br />
              Last-Modified: Wed, 21 Apr 2022 21:10:22 GMT<br />
              Etag: "13520-58c407930177e"<br />
              Accept-Rangers: bytes<br />
              Content-Length: 79136<br />
              Very: Accept-Encoding<br />
              Cache-Control: max-age=3600<br />
              Expires: Wed, 21 Apr 2022 22:15:15 gmt<br />
              Content-Type: text/html<br />
              <br />
              ip@CONNECT :-map nmap --script ssn-enum-ciphers ip-mktg.ip-connect.com -p 443<br />
              <br />
              Starting nmap 6.40 ( hhtp://nmap.org ) at 2022-04-21 16:07 CDT<br />
              <br />
              nmap scan report for ip-mktg.ip-connect.com (10.60.0.70)<br />
              Host is up (0.042s latency).<br />
              rDNS record for 10.60.0.70: in-addr.arpa.ip-connect.com<br />
              PORT STATE SERVICE<br />
              80/tcp open http<br />
              443/tcp open https<br />
              | ssl-enum-ciphers:<br />
              | TLSv1.0:<br />
              | ciphers:<br />
              | TLS_RSA_WITH_3DES_EDE_CBC_SHA - strong<br />
              | ‎‎‎TLS_RSA_WITH_AES_128_CBC_SHA‎-‎strong<br />
              ‎|‎‎‎‎‎‎‎TLS_RSA_WITH_AES_256_CBC_SHA‎-‎strong<br />
              ‎|‎‎‎‎‎compressors:<br />
              ‎|‎‎‎‎‎‎‎NULL<br />
              ‎|‎‎‎TLSv1.2:<br />
              ‎|‎‎‎‎‎ciphers:‎<br />
              ‎|‎‎‎‎‎‎‎TLS_RSA_WITH_3DES_EDE_CBC_SHA‎-‎strong<br />
              ‎|‎‎‎‎‎‎‎TLS_RSA_WITH_AES_128_CBC_SHA‎-‎strong<br />
              ‎|‎‎‎‎‎‎‎TLS_RSA_WITH_AES_128_GCM_SHA256‎-‎strong<br />
              ‎|‎‎‎‎‎‎‎TLS_RSA_WITH_AES_256_CBC_SHA‎-‎strong<br />
              ‎|‎‎‎‎‎‎‎TLS_RSA_WITH_AES_256_GCM_SHA384‎-‎‎strong<br />
              ‎|‎‎‎‎‎compressors:<br />
              ‎|‎‎‎‎‎‎‎NULL<br />
              ‎|_<br />
            </div>
            <div class="nmap-command-prompt" v-if="currentCMD === 4">
              ip@CONNECT‏‏‎ ‎:-#‏‏‎ ‎curl‏‏‎ ‎--head‏‏‎ ‎ip-pdc.ip-connect.com‏‏‎ ‎:‏‏‎ ‎443<br />
              <br />
              HTTP/1.1‏‏‎‏‏‎ ‎‎200‏‏‎‏‏‎ ‎‎OK<br />
              Date:‏‏‎‏‏‎ ‎‎Wed,‏‏‎‏‏‎ ‎‎26‏‏‎‏‏‎ ‎‎Jun‏‏‎‏‏‎ ‎‎2019‏‏‎‏‏‎ ‎‎21:15:15‏‏‎‏‏‎ ‎‎GMT<br />
              Server:‏‏‎‏‏‎ ‎‎Apache/2.4.48‏‏‎‏‏‎ ‎‎(CentOS)<br />
              Last-Modified:‏‏‎‏‏‎ ‎‎Wed,‏‏‎‏‏‎ ‎‎26‏‏‎‏‏‎ ‎‎Jun‏‏‎‏‏‎ ‎‎2019‏‏‎‏‏‎ ‎‎21:10:22‏‏‎‏‏‎ ‎‎GMT<br />
              Etag:‏‏‎‏‏‎ ‎‎"13520-58c407930177e"<br />
              Accept-Rangers:‏‏‎‏‏‎ ‎‎bytes<br />
              Content-Length:‏‏‎‏‏‎ ‎‎79136<br />
              Very:‏‏‎‏‏‎ ‎‎Accept-Encoding<br />
              Cache-Control:‏‏‎‏‏‎ ‎‎max-age=3600<br />
              Expires:‏‏‎‏‏‎ ‎‎Wed,‏‏‎‏‏‎ ‎‎26‏‏‎‏‏‎ ‎‎Jun‏‏‎‏‏‎ ‎‎2019‏‏‎‏‏‎ ‎‎22:15:15‏‏‎‏‏‎ ‎‎gmt<br />
              Content-Type:‏‏‎‏‏‎ ‎‎text/html<br />
              <br />
              ip@CONNECT‏‏‎‏‏‎ ‎‎:-#‏‏‎‏‏‎ ‎‎nmap‏‏‎‏‏‎ ‎‎--script‏‏‎‏‏‎ ‎‎ssl-enum-ciphers‏‏‎‏‏‎ ‎‎ip-mgmt.ip-connect.com‏‏‎‏‏‎ ‎‎-p‏‏‎‏‏‎
              ‎‎443<br />
              <br />
              Starting‏‏‎‏‏‎ ‎‎nmap‏‏‎‏‏‎ ‎‎6.40‏‏‎‏‏‎ ‎‎(‏‏‎‏‏‎ ‎‎http://nmap.org‏‏‎‏‏‎ ‎‎)‏‏‎‏‏‎ ‎‎at‏‏‎‏‏‎ ‎‎2022-04-21‏‏‎‏‏‎
              ‎‎16:07‏‏‎‏‏‎ ‎‎CDT<br />
              <br />
              nmap‏‏‎‏‏‎ ‎‎scan‏‏‎‏‏‎ ‎‎report‏‏‎‏‏‎ ‎‎for‏‏‎‏‏‎ ‎‎ip-mgmt.ip-connect.com‏‏‎‏‏‎ ‎‎(10.60.0.71)<br />
              Host‏‏‎‏‏‎ ‎‎is‏‏‎‏‏‎ ‎‎up‏‏‎‏‏‎ ‎‎(0.042s‏‏‎‏‏‎ ‎‎latency).<br />
              rDNS‏‏‎‏‏‎ ‎‎record‏‏‎‏‏‎ ‎‎for‏‏‎‏‏‎ ‎‎10.60.0.71:‏‏‎‏‏‎ ‎‎in-addr.arpa.ip-connect.com<br />
              Not‏‏‎‏‏‎ ‎‎shown:‏‏‎‏‏‎ ‎‎998‏‏‎‏‏‎ ‎‎filtered‏‏‎‏‏‎ ‎‎ports<br />
              PORT‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎STATE‏‏‎‏‏‎ ‎‎SERVICE<br />
              443/tcp‏‏‎‏‏‎ ‎‎open‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎https<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎TLSv1.2:<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎ciphers:<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎TLS_RSA-WITH-3DES-EDE-CBC-SHA‏‏‎‏‏‎ ‎‎-‏‏‎‏‏‎ ‎‎strong<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎TLS_RSA_WITH_AES_128_CBC_SHA‏‏‎‏‏‎ ‎‎-‏‏‎‏‏‎ ‎‎strong<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎TLS_RSA_WITH_AES_128_GCM_SHA256‏‏‎‏‏‎ ‎‎-‏‏‎‏‏‎ ‎‎strong<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎TLS_RSA_WITH_AES_256_CBC_SHA‏‏‎‏‏‎ ‎‎-‏‏‎‏‏‎ ‎‎strong<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎TLS_RSA_WITH_AES_256_CBC_SHA384‏‏‎‏‏‎ ‎‎-‏‏‎‏‏‎ ‎‎strong<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎compressors:<br />
              ‏‏‎‏‏‎ ‎‎|‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎‏‏‎‏‏‎ ‎‎NULL<br />
              ‏‏‎‏‏‎ ‎‎|_‏‏‎‏‏‎ ‎‎Least‏‏‎‏‏‎ ‎‎strentgh:‏‏‎‏‏‎ ‎‎strong<br />
              <br />
              nmap‏‏‎‏‏‎ ‎‎done:‏‏‎‏‏‎ ‎‎1‏‏‎‏‏‎ ‎‎IP‏‏‎‏‏‎ ‎‎adress‏‏‎‏‏‎ ‎‎(1‏‏‎‏‏‎ ‎‎host‏‏‎‏‏‎ ‎‎up)‏‏‎‏‏‎ ‎‎scanned‏‏‎‏‏‎ ‎‎in‏‏‎‏‏‎
              ‎‎8.63‏‏‎‏‏‎ ‎‎secconds<br />
              <br />
              <br />
              ip@CONNECT‏‏‎‏‏‎ ‎‎:-#‏‏‎‏‏‎ ‎‎nmap‏‏‎‏‏‎ ‎‎--top-ports‏‏‎‏‏‎ ‎‎10‏‏‎‏‏‎ ‎‎ip-mgmt.ip-connect.com<br />
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      currentPage: 0,
      currentCMD: 1,

      reportAnalysis: [
        {
          name: 'ip-pdc is only using TLS 1.2',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'ip-bdc is only using TLS 1.2',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'ip-mktg is only using TLS 1.2',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'ip-mgmt is only using TLS 1.2',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'ip-pdc is using Apache 2.4.18 or greater',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'ip-bdc is using Apache 2.4.18 or greater',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'ip-mktg is using Apache 2.4.18 or greater',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'ip-mgmt is using Apache 2.4.18 or greater',
          value: false,
          answer: true,
          marked: null,
        },
      ],
      reportAnalysisComplete: null,

      configurationChangeComplete: null,
      configurationOptions: [
        ['ip-pdc', 'ip-bdc', 'ip-mktg', 'ip-mgmt'],
        ['Apache Version', 'HTTPD Security', 'SSH Security'],
        [
          'Upgrade Version',
          'Downgrade Version',
          'Restrict to TLS 1.3',
          'Restrict to TLS 1.2',
          'Restrict to TLS 1.1',
          'Restrict to TLS 1.0',
        ],
      ],
      configurationChange: [
        {
          id: 0,
          fields: [
            {
              name: 'Server',
              value: 'ip-bdc',
              answer: ['ip-bdc'],
              marked: null,
              optionsId: 0,
              blacklistBankId: false,
            },
            {
              name: 'Service',
              value: '',
              answer: ['Apache Version', 'HTTPD Security'],
              marked: null,
              optionsId: 1,
              blacklistBankId: 0,
            },
            {
              name: 'Config Change',
              value: '',
              answer: ['Upgrade Version', 'Restrict to TLS 1.2'],
              marked: null,
              optionsId: 2,
              blacklistBankId: 1,
            },
          ],
        },
        {
          id: 0,
          fields: [
            {
              name: 'Server',
              value: 'ip-bdc',
              answer: ['ip-bdc'],
              marked: null,
              optionsId: 0,
              blacklistBankId: false,
            },
            {
              name: 'Service',
              value: '',
              answer: ['Apache Version', 'HTTPD Security'],
              marked: null,
              optionsId: 1,
              blacklistBankId: 0,
            },
            {
              name: 'Config Change',
              value: '',
              answer: ['Upgrade Version', 'Restrict to TLS 1.2'],
              marked: null,
              optionsId: 2,
              blacklistBankId: 1,
            },
          ],
        },
        {
          id: 0,
          fields: [
            {
              name: 'Server',
              value: 'ip-mktg',
              answer: ['ip-mktg'],
              marked: null,
              optionsId: 0,
              blacklistBankId: false,
            },
            {
              name: 'Service',
              value: '',
              answer: ['HTTPD Security'],
              marked: null,
              optionsId: 1,
              blacklistBankId: false,
            },
            {
              name: 'Config Change',
              value: '',
              answer: ['Restrict to TLS 1.2'],
              marked: null,
              optionsId: 2,
              blacklistBankId: false,
            },
          ],
        },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;
      let success = true;
      const blacklist = { 0: [], 1: [] };

      this.reportAnalysis.forEach((question, index) => {
        if (question.value === question.answer) {
          this.reportAnalysis[index].marked = true;
          totalScore++;
        } else {
          success = false;
          this.reportAnalysis[index].marked = false;
        }
      });

      this.reportAnalysisComplete = success;

      this.configurationChange.forEach((config, configIndex) => {
        config.fields.forEach((field, fieldIndex) => {
          if (field.answer.includes(field.value)) {
            if (field.blacklistBankId !== false) {
              if (blacklist[field.blacklistBankId].includes(field.value)) {
                this.configurationChange[configIndex].fields[fieldIndex].marked = false;
                success = false;
                return;
              }
              blacklist[field.blacklistBankId].push(field.value);
            }

            this.configurationChange[configIndex].fields[fieldIndex].marked = true;
            totalScore++;
          } else {
            success = false;
            this.configurationChange[configIndex].fields[fieldIndex].marked = false;
          }
        });
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
  },
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
